import React, { useState } from "react";
import { Container } from "react-bootstrap";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../assets/UserCss/css/Blogs/BlogDetails.css";
import { useParams } from "react-router";
import BlogDetailsHook from "../../Hooks/BlogsHook/BlogDetailsHook";
import Navbar1 from "../Uitily/Navbar";
import Footer from "../Uitily/Footer";
import parse from "html-react-parser";
import img from "../../assets/img/blog-header.jpg";
import { Link } from "react-router-dom";
import { Clock, Person } from "react-bootstrap-icons";

const BlogDetails = () => {
  const BlogId = useParams();
  const [Blog] = BlogDetailsHook(BlogId);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <div>
      <Navbar1 />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>{Blog ? Blog.titleAr : "Blog Details"}</h2>
          <ol>
            <li>{Blog ? Blog.titleAr : "Blog Details"}</li>
            <li>
              <Link href="/">الرئيسية</Link>
            </li>
          </ol>
        </div>
      </div>
      <Container>
        <h1 className="Blog-header"> {Blog ? Blog.titleAr : "title"}</h1>
        <div className="Blog d-flex col-lg-12  ">
          <article className="blog-details col-lg-10 ">
            <div className="post-img">
              {Blog && Blog.imageCover && (
                <img
                  src={Blog.imageCover}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "contain" }}
                />
              )}
            </div>

            <div className="content">
              <p>{Blog ? parse(Blog.descriptionAr) : null}</p>
            </div>

            {/* <div className="meta-bottom">
              <i className="bi bi-folder"></i>
              <ul className="cats">
                <li>
                  <a href="#">Business</a>
                </li>
              </ul>

              <i className="bi bi-tags"></i>
              <ul className="tags">
                <li>
                  <a href="#">Creative</a>
                </li>
                <li>
                  <a href="#">Tips</a>
                </li>
                <li>
                  <a href="#">Marketing</a>
                </li>
              </ul>
            </div> */}
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              slidesPerView={1}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
              breakpoints={{
                // When window width is <= 768px (for mobile)
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                //
                // Add more breakpoints as needed
              }}
            >
              {Blog && Blog.images
                ? Blog.images.map((BlogImg, index) => (
                    <SwiperSlide key={index}>
                      <img src={BlogImg} alt="" />
                    </SwiperSlide>
                  ))
                : null}
            </Swiper>
          </article>
          <div className="meta-top">
            <ul>
              <li className="d-flex align-items-center">
                <Person style={{ marginLeft: "5px" }} />
                <span>جودي للأستثمار</span>
              </li>
              <li className="d-flex align-items-center">
                <Clock style={{ marginLeft: "5px" }} />
                <time datetime="2020-01-01">
                  {Blog && Blog.createdAt ? Blog.createdAt.split("T")[0] : null}
                </time>
              </li>
              {/* <li className="d-flex align-items-center">
                        <i className="bi bi-chat-dots"></i>{" "}
                        <Link to="/blog/blog-details">12 Comments</Link>
                      </li> */}
            </ul>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default BlogDetails;
