import { useEffect, useState } from "react";
import call_us from "../../assets/img/citizinship/contact-us.png";
import "../../Pages/TurkishCitizenshi/style.css";

const FloatingButton = ({ targetId }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        const { top } = targetSection.getBoundingClientRect();
        setIsVisible(top > window.innerHeight || top < 0);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [targetId]);

  const handleClick = () => {
    const targetSection = document.getElementById(targetId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <button
      className={`float_button ${isVisible ? "visible" : "hidden"}`}
      onClick={handleClick}
    >
      <img src={call_us} alt="" />
    </button>
  );
};

export default FloatingButton;
