import React from "react";
import img from "../../assets/img/blog-header.jpg";
import { Link } from "react-router-dom";
const BlogMine = () => {
  return (
    <div
      className="breadcrumbs d-flex align-items-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="container position-relative d-flex flex-column align-items-center">
        <h2>المقالات </h2>
        <ol>
          <li>المقالات </li>
          <li>
            <Link to="/">الرئيسية</Link>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default BlogMine;
