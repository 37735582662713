import React from "react";
import BlogPagination from "./BlogPagination";
import "../../assets/UserCss/css/Blogs/BlogPost.css";
import { Link } from "react-router-dom";
import AllBlogsHook from "../../Hooks/BlogsHook/All-Blogs-Hook";
import parse from "html-react-parser";

const BlogPost = () => {
  const [BlogsRes, PageCount, getBlogsWithPage] = AllBlogsHook();
  return (
    <div
      className="col-lg-12"
      data-aos="fade-up"
      data-aos-delay="200"
      style={{ direction: "rtl" }}
    >
      <div className="row gy-5 posts-list">
        {BlogsRes && BlogsRes.data
          ? BlogsRes.data?.map((item, index) => (
              <div className=" post" key={index}>
                <div className="post-img">
                  <Link to={`/Blogs/${item._id}`}>
                    <img src={item.imageCover} alt="" className="img-fluid" />
                  </Link>
                </div>
                <div className="content">
                  {" "}
                  <h2 className="title">
                    <Link to={`/Blogs/${item._id}`}>{item.titleAr}</Link>
                  </h2>
                  <div className="postDate">
                    <p className="d-flex align-items-center">
                      <i className="bi bi-clock text-primary"></i>{" "}
                      <time datatime="2022-01-01">
                        {item.createdAt.substring(0, 10)}
                      </time>
                    </p>
                  </div>
                  <div className="text">
                    <p>{parse(item.descriptionAr.substring(0, 50))}...</p>
                  </div>
                  <div className="read-more mt-auto align-self-end">
                    <Link to={`/Blogs/${item._id}`}>
                      المزيد <i className="bi bi-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
      <BlogPagination PageCount={PageCount} onPress={getBlogsWithPage} />
    </div>
  );
};

export default BlogPost;
