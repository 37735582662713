import baseURL from "../../api/baseURL";

// //لاحضار البيانات  Get all data

const useGetData = async (url, parmas) => {
  try {
    const res = await baseURL.get(url, parmas);
    return res;
  } catch (e) {
    return;
  }
};

export default useGetData;
