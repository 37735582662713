import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import gameflakes from "../../assets/img/logos/gameflakes.png";
import nooncar from "../../assets/img/logos/nooncar.png";
import noonmar from "../../assets/img/logos/noonmar.png";
import smart from "../../assets/img/logos/smart.jpeg";
import soonkitchen from "../../assets/img/logos/soonkitchen.png";
import tek from "../../assets/img/logos/tek.png";

const ServicesCards = () => {
  return (
    <section
      id="services-cards"
      className="services-cards"
      style={{ direction: "rtl" }}
    >
      <Container data-aos="fade-up">
        <div className="section-header">
          <h2>شركاتنا</h2>
        </div>
        <Row className="gy-4">
          {/* Noontek */}
          <Col
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            xl={4}
          >
            <div
              className="card-item"
              style={{
                borderRadius: "30px",
                boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
              }}
            >
              <Row>
                <Col xl={12}>
                  <div
                    className="card-bg"
                    style={{
                      backgroundImage: `url(${tek})`,
                      backgroundSize: "contain",
                    }}
                  ></div>
                </Col>
                <Col xl={12} className="d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">NOONTEK</h4>
                    <p style={{ textAlign: "justify" }}>
                      تأسست شركة NOONTEK عام 2014 كشركة مساهمة متخصصة في مجالات
                      التكنولوجيا، الاتصالات، والمعلوماتية، حيث تقدم حلولاً
                      إلكترونية متكاملة. بفضل خبرة تمتد لأكثر من 25 عاماً في
                      قطاع التكنولوجيا، أصبحت نون تك شريكاً موثوقاً للعملاء في
                      مختلف الصناعات.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          {/* Noonmar */}
          <Col
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            xl={4}
          >
            <div
              className="card-item"
              style={{
                borderRadius: "30px",
                boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
              }}
            >
              <Row>
                <Col xl={12}>
                  <div
                    className="card-bg"
                    style={{
                      backgroundImage: `url(${noonmar})`,
                      backgroundSize: "contain",
                    }}
                  ></div>
                </Col>
                <Col xl={12} className="d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">NOONMAR</h4>
                    <p style={{ textAlign: "justify" }}>
                      نون مار هي شركة تركية متعددة الجنسيات مقرها في إسطنبول،
                      تعمل كمنصة للتجارة الإلكترونية الدولية تربط بين المستهلكين
                      حول العالم والشركات التركية. تأسست الشركة في عام 2017،
                      وأطلقت منصتها الخاصة بالصادرات الإلكترونية الدولية في عام
                      2019، مع تطلعها إلى أن تصبح قصة نجاح بارزة في قطاع التجارة
                      الإلكترونية.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          {/* SmartINB */}
          <Col
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            xl={4}
          >
            <div
              className="card-item"
              style={{
                borderRadius: "30px",
                boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
              }}
            >
              <Row>
                <Col xl={12}>
                  <div
                    className="card-bg"
                    style={{
                      backgroundImage: `url(${smart})`,
                      backgroundSize: "contain",
                    }}
                  ></div>
                </Col>
                <Col xl={12} className="d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">SMARTİNB</h4>
                    <p style={{ textAlign: "justify" }}>
                      تعد Smartinb Solutions شركة رائدة في مجال توريد وابتكار
                      الحلول البرمجية، حيث تتمتع بخبرة واسعة في تقديم الاستشارات
                      التقنية ودعم المشاريع لتحقيق أهدافها الاستراتيجية من خلال
                      الأدوات الرقمية المتطورة.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          {/* Soon Kitchen */}
          <Col
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            xl={4}
          >
            <div
              className="card-item"
              style={{
                borderRadius: "30px",
                boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
              }}
            >
              <Row>
                <Col xl={12}>
                  <div
                    className="card-bg"
                    style={{
                      backgroundImage: `url(${soonkitchen})`,
                      backgroundSize: "contain",
                    }}
                  ></div>
                </Col>
                <Col xl={12} className="d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">Soon Kitchen</h4>
                    <p style={{ textAlign: "justify" }}>
                      مطبخ سون كيتشن هو مطبخ سحابي مبتكر مقره في إسطنبول، مع فرع
                      رئيسي في مدينة غازي عنتاب. يضم المطبخ 13 علامة تجارية
                      تتبنى مفهوم المطبخ الافتراضي، والذي يركز على تصنيع الطعام
                      للماركات المختلفة وتقديم بنية تحتية وخدمات لوجستية متكاملة
                      في مساحة جغرافية محددة.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          {/* Nooncar */}
          <Col
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            xl={4}
          >
            <div
              className="card-item"
              style={{
                borderRadius: "30px",
                boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
              }}
            >
              <Row>
                <Col xl={12}>
                  <div
                    className="card-bg"
                    style={{
                      backgroundImage: `url(${nooncar})`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                </Col>
                <Col xl={12} className="d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">NOON CAR</h4>
                    <p style={{ textAlign: "justify" }}>
                      تعد NOONCAR من الشركات البارزة في مجال إعادة تجديد
                      السيارات المتضررة من الحوادث، حيث تركز على استعادة هذه
                      السيارات إلى حالتها المثلى للقيادة، مع ضمان توفير جميع
                      الوثائق اللازمة، بما في ذلك شهادات المواصفات وتوثيق عمليات
                      الصيانة والتجديد بشكل دقيق.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          {/* Game Flakes */}
          <Col
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            xl={4}
          >
            <div
              className="card-item"
              style={{
                borderRadius: "30px",
                boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
              }}
            >
              <Row>
                <Col xl={12}>
                  <div
                    className="card-bg"
                    style={{
                      backgroundImage: `url(${gameflakes})`,
                      backgroundSize: "contain",
                    }}
                  />
                </Col>
                <Col xl={12} className="d-flex align-items-center">
                  <div className="card-body">
                    <h4 className="card-title">Game Flakes</h4>
                    <p style={{ textAlign: "justify" }}>
                      تعد Game Flakes واحدة من الشركات الرائدة في مجال الابتكار
                      والإبداع في تطوير الألعاب الإلكترونية، حيث تقدم تجارب
                      ترفيهية متكاملة من خلال تصميم إبداعي وتطوير برمجيات
                      متقدمة. تهتم الشركة بإنشاء الشخصيات والرسومات بأسلوب فني
                      مميز، مع تحسين الأصوات والتأثيرات الصوتية لضمان تجربة لعب
                      غامرة وممتعة.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServicesCards;
