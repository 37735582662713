import { createSlice } from "@reduxjs/toolkit";
import getAllBlogs, {
  CreateBlog,
  DeleteBlog,
  EditBlog,
  getBlogsOfPage,
  getOneBlog,
} from "../../Redux/Actions/BlogActions";

const initialState = {
  Blogs: [],
  oneBlog: [],
  BlogDeleted: [],
  BlogUpdated: [],
  BlogCreated: [],
  isloading: true,
  error: null,
};

const BlogsSlice = createSlice({
  name: "Brands",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllBlogs.fulfilled, (state, action) => {
      state.Blogs = action.payload;
      state.isloading = false;
    });
    builder.addCase(getOneBlog.fulfilled, (state, action) => {
      state.oneBlog = action.payload;
      state.isloading = false;
    });
    builder.addCase(EditBlog.fulfilled, (state, action) => {
      state.BlogUpdated = action.payload;
      state.isloading = false;
    });
    builder.addCase(DeleteBlog.fulfilled, (state, action) => {
      state.BlogDeleted = action.payload;
      state.isloading = false;
    });
    builder.addCase(CreateBlog.fulfilled, (state, action) => {
      state.BlogCreated = action.payload;
      state.isloading = false;
    });
    builder.addCase(getBlogsOfPage.fulfilled, (state, action) => {
      state.Blogs = action.payload;
      state.isloading = false;
    });
  },
});

export default BlogsSlice.reducer;
