import { Container, Row } from "react-bootstrap";

const HomeMine = () => {
  return (
    <section
      id="hero"
      className="section hero d-flex align-items-center"
      style={{ direction: "rtl" }}
    >
      <Container>
        <Row>
          <div className="text-col" style={{ direction: "rtl" }}>
            <h2 data-aos="fade-up">الصناديق الاستثمارية</h2>

            <p style={{ textAlign: "justify", color: "#fff" }}>
              محفظة استثمارية لجذب الاستثمارات التي تبحث عن ملاذ آمن في السوق
              التركي، تم تأسيسه بالتعاون مع شركة 24Portfoy المتخصصة بإدارة
              الصناديق، ويتم مراقبة الصندوق من عدة جهات خارجية على رأسها هيئة
              الأسواق المالية التركية، ويمتلك الصندوق الحصة الأكبر في شركة جودي
              للاستثمار، التي تقوم بإدارة استثمارات المساهمين وتوجيهها للقطاعات
              الآمنة والأكثر ربحاً في السوق التركي
            </p>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default HomeMine;
