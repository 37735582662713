import React from "react";
import img from "../../assets/img/judyimg.jpeg";
import { BriefcaseFill, Cpu, CupHotFill, EvFront } from "react-bootstrap-icons";
const HomeYourBusiness = () => {
  return (
    <section id="features" className="features" style={{ direction: "rtl" }}>
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-7" data-aos="fade-up" data-aos-delay="100">
            <h3>قطاعات الاستثمار</h3>

            <div className="row gy-4">
              <div className="col-md-6">
                <div>
                  <BriefcaseFill
                    style={{ marginLeft: "10px", color: "#06515a" }}
                  />

                  <span>قطاع ريادة الاعمال</span>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <CupHotFill
                    style={{ marginLeft: "10px", color: "#06515a" }}
                  />

                  <span>قطاع الصناعات الغذائية</span>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <Cpu style={{ marginLeft: "10px", color: "#06515a" }} />

                  <span>القطاع التكنولوجي</span>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <EvFront style={{ marginLeft: "10px", color: "#06515a" }} />
                  <i class="fa-solid fa-cars"></i>
                  <span>قطاع السيارات</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 position-relative"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="phone-wrap">
              <img src={img} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="details">
        <div className="container" data-aos="fade-up" data-aos-delay="300">
          <div className="row">
            <div className="col-md-6">
              <div>
                <h4>الصناديق الاستثمارية</h4>
                <p style={{ textAlign: "justify" }}>
                  تعتبر الصناديق الاستثمارية خياراً جيداً للاستثمار فهي محفظة
                  استثمارية تديرها شركات إدارة الصناديق، وتعتبر هذه المحافظ
                  وسيلة آمنة للاستثمار حيث يقوم المستثمرون بإيداع أموالهم بها
                  بهدف تحقيق الأرباح. تتميز هذه الصناديق بأنها تخضع لرقابة صارمة
                  من قبل الجهات الحكومية وشركات المراجعة الخارجية، مما يجعلها
                  واحدة من أكثر الطرق أماناً للاستثمار.{" "}
                </p>

                {/* <a href={item.link} className="btn-get-started">
                          {content.GetStarted}
                        </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeYourBusiness;
