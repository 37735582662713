import React from "react";
import img from "../../assets/img/Aboutus2.jpeg";
import "./About.css";

const AboutSection = () => {
  return (
    <section id="about" className="about container">
      <div className="row gy-4 " data-aos="fade-up">
        <div className="col-lg-4 about_img">
          <img src={img} alt="" style={{ objectFit: "" }} />
        </div>
        <div className="col-lg-8">
          <div className="about_txt">
            <h3>من نحن :</h3>
            <p>
              شركة متخصصة بإدارة الاستثمار في السوق التركي، وتأسيس الشركات،
              وتقديم الاستشارات المالية والقانونية والتكنولوجية للشركات والأفراد
              تأسست الشركة عام 2013 كشركة متخصصة باستيراد وتصدير المنتجات
              الإلكترونية، وفي عام 2014 تحول لشركة تعمل على إبداع الأفكار
              وتحويلها لمشاريع ومن ثم لشركات مساهمة وطرحها للاستثمار، وتعتبر
              شركة NOONTEK المتخصصة بالتكنولوجيا أول شركة مساهمة تم إطلاقها عام
              2014 وفي عام 2020 تحولت الشركة للاستثمار المؤسساتي، بتأسيس صناديق
              الاستثمار والحصول على الاستثمارات من خارج تركيا، وكان لجودي في
              الاستثمار دور كبير في تطوير و تأسيس العديد من المشاريع الاستثمارية
              في تركيا و حصول المستثمرين على الجنسية التركية.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
