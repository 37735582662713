import React from "react";
import { Link } from "react-router-dom";

const ServicesMain = () => {
  
  return (
    <div>
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{
          // backgroundImage: `url(${
          //   res ? (res.data ? res.data[0].image : null) : null
          // })`,
        }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>خدماتنا</h2>
          <ol>
            <li>
              <Link to="/">الرئيسية</Link>
            </li>
            <li>خدماتنا</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default ServicesMain;
