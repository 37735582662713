import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EditBlog, getOneBlog } from "../../Redux/Actions/BlogActions";

const UpdateBlogHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BlogRes = useSelector((state) => state.BlogsData.oneBlog);
  const UpdatedBlogRes = useSelector((state) => state.BlogsData.BlogUpdated);
  const [show, setShow] = useState(false);
  const [ArBlogTitle, setArBlogTitle] = useState("");
  const [TrBlogTitle, setTrBlogTitle] = useState("");
  const [ArBlogDesc, setArBlogDesc] = useState("");
  const [TrBlogDesc, setTrBlogDesc] = useState("");
  const [imageCover, setImageCover] = useState([]);
  const [images, setImages] = useState([]);
  const [Loading, setLoading] = useState(true);

  //
  useEffect(() => {
    if (Loading === false) {
      if (UpdatedBlogRes?.status === 200) {
        toast.success("The Blog has been updated Succesfully", {
          autoClose: 2000,
          className: "toast-success",
        });
        setTimeout(() => {
          navigate("/Dashboard/Blogs");
        }, 2000);
      } else {
        toast.error("something went Wrong", {
          autoClose: 2000,
          className: "toast-error",
        });
      }
    }
  }, [Loading]);
  // convert from base 64 to img file javascript

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  //convert url to file
  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    // const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], Math.random(), metadata);
  };
  // get The Blog Data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let Blogdata = {};
  if (BlogRes) Blogdata = BlogRes.data;
  useEffect(() => {
    dispatch(getOneBlog(id));
  }, []);
  useEffect(() => {
    if (Blogdata) {
      setArBlogTitle(Blogdata?.titleAr);
      setArBlogDesc(Blogdata?.descriptionAr);
      setTrBlogTitle(Blogdata?.titleTr);
      setTrBlogDesc(Blogdata?.descriptionTr);
      setImages(Blogdata?.images);
      setImageCover([Blogdata?.imageCover]);
    }
  }, [Blogdata]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const promises = Object.values(images).map((image) => {
        if (image.startsWith("data:image")) {
          return dataURLtoFile(image, Math.random() + ".png");
        } else {
          return convertURLtoFile(image);
        }
      });

      let imageCoverFile = imageCover;
      if (typeof imageCover === "string" && imageCover.startsWith("data:")) {
        imageCoverFile = dataURLtoFile(imageCover, "cover.png");
      }

      const itemImages = await Promise.all(promises);
      const formData = new FormData();
      formData.append("titleAr", ArBlogTitle);
      formData.append("descriptionAr", ArBlogDesc);
      formData.append("titleTr", TrBlogTitle);
      formData.append("descriptionTr", TrBlogDesc);
      formData.append("imageCover", imageCoverFile);
      itemImages.map((img) => formData.append("images", img));
      await dispatch(EditBlog({ id: id, data: formData }));
      setShow(false);
      setLoading(false);
    } catch (error) {
      console.error("Error handling update:", error);
    }
  };

  // set Arabic State Values
  const onArBlogTitleChange = (e) => {
    e.persist();
    setArBlogTitle(e.target.value);
  };

  const onArBlogDescChange = (e, editor) => {
    const data = editor.getData();
    setArBlogDesc(data);
  };

  // set Turkish State Values
  const onTrBlogTitleChange = (e) => {
    e.persist();
    setTrBlogTitle(e.target.value);
  };
  const onTrBlogDescChange = (e, editor) => {
    const data = editor.getData();
    setTrBlogDesc(data);
  };

  return {
    show,
    images,
    imageCover,
    setImages,
    setImageCover,
    ArBlogTitle,
    ArBlogDesc,
    TrBlogTitle,
    TrBlogDesc,
    onArBlogTitleChange,
    onArBlogDescChange,
    onTrBlogTitleChange,
    onTrBlogDescChange,
    handleClose,
    handleShow,
    handleUpdate,
  };
};

export default UpdateBlogHook;
