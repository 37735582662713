import React from "react";
// import TranslatHook from "./../../hook/translat-hook";

import ContactForm from "../../Components/TurkishCitizenship/ContactForm";
import BenefitCard from "../../Components/TurkishCitizenship/BenefitCard";
import "./style.css";
import img1 from "../../assets/img/citizinship/passport-ticket.png";
import img2 from "../../assets/img/citizinship/conversation.png";
import img3 from "../../assets/img/citizinship/money.png";
import img4 from "../../assets/img/citizinship/deposit.png";
import img5 from "../../assets/img/citizinship/bank.png";
import img6 from "../../assets/img/citizinship/stamp.png";
import img7 from "../../assets/img/citizinship/google-docs.png";
import img8 from "../../assets/img/citizinship/investment.png";
import img9 from "../../assets/img/citizinship/financial-profit (1).png";
import whatsApp from "../../assets/img/citizinship/apple.png";
import call_us from "../../assets/img/citizinship/contact-us.png";
import imgCover from "../../assets/img/citizinship/271220231-696x392.png";
import Arrow from "../../assets/img/citizinship/down-right-arrow.png";
import Arrow2 from "../../assets/img/citizinship/right-arrow.png";
import judyLogo from "../../assets/img/logos/judy.png";
import { EnvelopeAtFill, TelephoneInboundFill } from "react-bootstrap-icons";
import FloatingButton from "../../Components/TurkishCitizenship/FloatingButton";

const TurkishCitizenshiPage = () => {
  //const [lang] = TranslatHook();

  const data = [
    { title: " الحصول على الجنسية التركية", img: img1 },
    { title: "  الإستشارات ", img: img2 },
    { title: " الإعفاء الضريبي ", img: img3 },
    { title: "   فتح حساب البنكي و الاستثمار ", img: img4 },
    { title: " استثمار آمن و متنوع تحت إشراف مؤسسات حكومية", img: img5 },
    { title: " متابعة إجراءات الجنسية ", img: img6 },
  ];

  const renderBenefitCards = () => {
    const rows = [];
    for (let i = 0; i < data.length; i += 2) {
      const row = (
        <div className="d-flex mb-4" key={i}>
          <BenefitCard title={data[i].title} img={data[i].img} />
          {data[i + 1] && (
            <BenefitCard title={data[i + 1].title} img={data[i + 1].img} />
          )}
        </div>
      );
      rows.push(row);
    }
    return rows;
  };
  const handleWhatsApp = () => {
    const phoneNumber = "+905373063891";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  const handleCallClick = () => {
    const phoneNumber = "+905373063891";
    const url = `tel:${phoneNumber}`;
    window.open(url, "_self"); // '_self' ensures it opens in the same tab
  };
  return (
    <div style={{ direction: "rtl" }}>
      {/* <Navbar1 /> */}
      <div className="page-investment">
        <main id="main">
          {/* Landing */}
          <div className="Header">
            <div className="page_container  d-flex" style={{ height: "100%" }}>
              <img src={imgCover} alt="cover" className="col-6" />
              <h1>استثمر في تركيا واحصل على الجنسية التركية مع عائلتك</h1>
            </div>
          </div>
          {/* Content */}
          <div className="content d-flex page_container">
            <div className="content_txt col-6 p-2">
              <div className="d-flex">
                <img src={Arrow} alt="" />
                <p className="me-4" style={{ textAlign: "justify" }}>
                  استثمر الآن للحصول على الجنسية التركية عبر الودائع و الصناديق
                  الاستثمارية التي توفر لك العديد من المزايا والخيارات
                  الاستثمارية مع عوائد مجزية، بإشراف ورقابة مؤسسات حكومية
                </p>
              </div>
              <div className="mt-4"></div>
            </div>
            <div className="content_ul col-5">
              <div className="d-flex mb-2">
                <h4 className="col-6 ">
                  <img src={Arrow2} alt="" />
                  المزايا
                </h4>
                <h4 className=" col-6">
                  <img src={Arrow2} alt="" /> الخدمات
                </h4>
              </div>
              {renderBenefitCards()}
            </div>
          </div>
          {/* Items? I dunno what to call it */}
          <div className="invest_types ">
            <div className="overlay"></div>
            <div
              className="invest_icons d-flex page_container
            "
            >
              <div className="col-4">
                <img src={img7} alt="" />
                <p className="mt-3">الودائع أو السندات</p>
              </div>
              <div className="col-4">
                <img src={img8} alt="" />
                <p className="mt-3">الاستثمار بالأصول</p>
              </div>
              <div className="col-4">
                <img src={img9} alt="" />
                <p className="mt-3">فتح نشاط تجاري</p>
              </div>
            </div>
          </div>
          {/* contact Form */}
          <div id="contact_form" className="d-flex contact_us  page_container">
            <div className="contact_icons col-6">
              <button className="d-flex Call_button" onClick={handleCallClick}>
                <span className="col-8">contact us</span>
                <img className="col-3" src={call_us} alt="" />
              </button>
              <button
                className="d-flex whatsApp_button"
                onClick={handleWhatsApp}
              >
                <span className="col-8">whatsApp</span>
                <img className="col-3" src={whatsApp} alt="" />
              </button>
            </div>
            <div className="col-6 contact_form">
              <h4>لتفاصيل اكثر لا تتردد بالتواصل معنا الاستشارة مجانية </h4>
              <ContactForm />
            </div>
          </div>
          {/* Footer */}
          <div className="footer d-flex">
            <div className="col-3">
              <img src={judyLogo} alt="" />
            </div>
            <div className="col-6 d-flex">
              <div className="phone">
                <TelephoneInboundFill color="#e8af84" size={18} />
                <span>+90 537 306 38 91</span>
              </div>
              <div className="Email">
                <EnvelopeAtFill color="#e8af84" size={18} />
                <span>contact@judyinvest.com</span>
              </div>
            </div>
          </div>
        </main>
        {/* Floating Button*/}
        <FloatingButton targetId="contact_form" />
      </div>
    </div>
  );
};

export default TurkishCitizenshiPage;
