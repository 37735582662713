import React, { useState, useEffect } from "react";
import gold from "../../assets/img/gold.jpeg";
import gomus from "../../assets/img/gomus.jpeg";

const FundTypes = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      console.log(isMobile);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const containerStyle = isMobile ? "30px 30px 0px 0" : "0 30px 30px 0";

  return (
    <>
      <section
        id="services-cards"
        className="services-cards"
        style={{ direction: "rtl" }}
      >
        <div className="section-header">
          <h2>أنواع الصناديق في جودي</h2>
        </div>
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div
                className="card-item"
                style={{
                  height: "100%",
                  borderRadius: "30px",
                  boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
                }}
              >
                <div className="row" style={{ height: "100%" }}>
                  <div className="col-xl-5">
                    <div
                      className="card-bg2"
                      style={{
                        backgroundImage: `url(${gold})`,
                        borderRadius: containerStyle,
                      }}
                    ></div>
                  </div>

                  <div className="col-xl-7 d-flex align-items-center">
                    <div className="card-body">
                      <h4 className="card-title">NOONTEK</h4>
                      <p style={{ textAlign: "justify" }}>
                        صندوق متخصص بالتكنولوجيا و ريادة الأعمال، تم تأسيس في
                        عام 2022 وتندرج تحته العديد من الشركات وعلى رأسها شركة
                        جودي للاستثمار، وتتنوع قطاعات الاستثمار في التكنولوجيا
                        وانتاج الطعام وتجديد السيارات.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div
                className="card-item"
                style={{
                  height: "100%",
                  borderRadius: "30px",
                  boxShadow: "0 4px 8px rgba(6, 81, 90, 0.2)",
                }}
              >
                <div className="row" style={{ height: "100%" }}>
                  <div className="col-xl-5">
                    <div
                      className="card-bg2"
                      style={{
                        backgroundImage: `url(${gomus})`,
                        borderRadius: containerStyle,
                      }}
                    ></div>
                  </div>

                  <div className="col-xl-7 d-flex align-items-center">
                    <div className="card-body">
                      <h4 className="card-title">PLATNUM</h4>
                      <p style={{ textAlign: "justify" }}>
                        هذا الصندوق متخصص في الاستثمار في الصكوك (السندات
                        الإسلامية) ويقدم خدمات الحصول على الجنسية التركية. يوفر
                        الصندوق محفظة استثمارية تتمتع بمستوى عالٍ من الأمان، مما
                        يضمن تحقيق أهداف المستثمرين بأقصى درجة من الحماية
                        والموثوقية.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FundTypes;
