import React from "react";
import "./contact.css";
import shape from "./img/shape.png";
import location from "./img/location.png";
import email from "./img/email.png";
import phone from "./img/phone.png";
import { Facebook, Instagram, Linkedin, Twitter } from "react-bootstrap-icons";
const ContactNew = () => {
  return (
    <div className="container-contact">
      <span className="big-circle"></span>
      <img src={shape} className="square" alt="" />
      <div className="form" style={{ marginTop: "30px" }}>
        <div className="contact-info">
          <h3 className="title">ابقى على تواصل معنا </h3>
          <p className="text">
            في حال كان لديك أي أسئلة أو واجهت أي مشاكل، يمكنك الاتصال بنا عن
            طريق ملء النموذج
          </p>

          <div className="info">
            <div className="information">
              <img src={location} className="icon" alt="" />
              <p className="paragraf-contant">
                MAHMUTBEY MAH.HACI BOSTAN CAD.NO.22 BAGCILAR - ISTANBUL
              </p>
            </div>
            <div className="information">
              <img src={email} className="icon" alt="" />
              <p className="paragraf-contant">info@judyinvest.com</p>
            </div>
            <div className="information">
              <img src={phone} className="icon" alt="" />
              <p className="paragraf-contant">00 39 532 850 09+</p>
            </div>
          </div>

          <div className="social-media">
            <p>اتصل بنا :</p>
            <div className="social-icons">
              <a
                href="https://x.com/judyinvest"
                target="blank"
                className="twitter"
              >
                <Twitter />
              </a>
              <a
                href="https://www.facebook.com/Judyinvest"
                target="blank"
                className="facebook"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/Judyinvest"
                target="blank"
                className="insta"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/company/judyinvest"
                target="blank"
                className="linkedin"
              >
                <Linkedin />
              </a>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <span className="circle one"></span>
          <span className="circle two"></span>

          <form action="index.html" autocomplete="off">
            <h3 className="title">تواصل معنا</h3>
            <div className="input-container">
              <input type="text" name="name" className="input" />
              <label for="">اسم المستخدم</label>
              <span>اسم المستخدم</span>
            </div>
            <div className="input-container">
              <input type="email" name="email" className="input" />
              <label for="">الايميل</label>
              <span>الايميل</span>
            </div>
            <div className="input-container">
              <input type="tel" name="phone" className="input" />
              <label for="">رقم الهاتف</label>
              <span>رقم الهاتف</span>
            </div>
            <div className="input-container textarea">
              <textarea name="message" className="input"></textarea>
              <label for="">رسالة</label>
              <span>رسالة</span>
            </div>
            <input type="submit" value="ارسل" className="btn" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactNew;
