import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import img from "../../assets/img/Aboutus22.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

const WhyChooseUs = () => {
  return (
    <section id="why-us" className="why-us">
      <Container data-aos="fade-up">
        <div className="section-header">
          <h2>من نحن</h2>
        </div>

        <Row
          className=" justify-content-between g-0"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Col
            xl={5}
            className=" img-bg"
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></Col>

          <Col xl={6} className="position-relative">
            <Swiper
              spaceBetween={100}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              loop={true}
              pagination={{ clickable: true }}
              modules={[Pagination, Navigation]}
            >
              <SwiperSlide>
                <div className="item " style={{ direction: "rtl" }}>
                  <h3 style={{ marginBottom: "30px" }}>من نحن</h3>

                  <p style={{ textAlign: "justify" }}>
                    شركة متخصصة بإدارة الاستثمار في السوق التركي، وتأسيس
                    الشركات، وتقديم الاستشارات المالية والقانونية والتكنولوجية
                    للشركات والأفراد تأسست الشركة عام 2013 كشركة متخصصة باستيراد
                    وتصدير المنتجات الإلكترونية، وفي عام 2014 تحول لشركة تعمل
                    على إبداع الأفكار وتحويلها لمشاريع ومن ثم لشركات مساهمة
                    وطرحها للاستثمار، وتعتبر شركة NOONTEK المتخصصة بالتكنولوجيا
                    أول شركة مساهمة تم إطلاقها عام 2014 وفي عام 2020 تحولت
                    الشركة للاستثمار المؤسساتي، بتأسيس صناديق الاستثمار والحصول
                    على الاستثمارات من خارج تركيا، وكان لجودي في الاستثمار دور
                    كبير في تطوير و تأسيس العديد من المشاريع الاستثمارية في
                    تركيا و حصول المستثمرين على الجنسية التركية.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item " style={{ direction: "rtl" }}>
                  <h3 style={{ marginBottom: "30px" }}>مهمتنا</h3>
                  <p style={{ textAlign: "justify" }}>
                    تأمين استثمار آمن للمساهمين،وابتكار الافكار الريادية في عالم
                    الأعمال وتحويلها لشركات مساهمة، ودعم الشركات الناشئة
                    بالاستشارات والتمويل
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item " style={{ direction: "rtl" }}>
                  <h3 style={{ marginBottom: "30px" }}>رؤية الشركة</h3>
                  <p style={{ textAlign: "justify" }}>
                    أن نكون شركة رائدة بإدارة الاستثمار في تركيا والشرق الأوسط،
                    وتطوير أدوات الاستثمار وأنواعه، ودعم الشركات الناشئة
                  </p>
                </div>
              </SwiperSlide>

              <div className="swiper-pagination"></div>
            </Swiper>

            <div className="swiper-button-next swiper-button-white"></div>
            <div className="swiper-button-prev swiper-button-white"></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyChooseUs;
